/* html5doctor-reset */
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent;margin:0;padding:0}
body{line-height:1}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
nav ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{font-size:100%;vertical-align:baseline;background:transparent;margin:0;padding:0}
ins{background-color:transparent;color:#000;text-decoration:none}
mark{background-color:transparent;color:#000;font-style:italic;font-weight:700}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
hr{display:block;height:1px;border:0;border-top:1px solid #141414;margin:1em 0;padding:0}
input,select{vertical-align:middle}

/*  PARENT  -----------------------------  */
html, body {
  padding: 0;
  margin: 0;
  overflow-x:hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-family: "Lato", Arial, sans-serif;
}

/* CLEARFIX ---------*/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;line-height: 0;content: "";
}
.clearfix:after {clear: both;
}
.clearfix:after {
  content: ".";display: block;clear: both;
  visibility: hidden;line-height: 0;height: 0;
}

*::selection { background:#111; color:#FFF;}
*::-moz-selection { background:#111; color:#FFF;}
*::-webkit-selection { background:#111; color:#FFF;}

a{
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
img{display: block;
}
img, video, embed, iframe{
  max-width: 100.1%;
  height: auto;
}
img, a img,fieldset, input {border:none;}
em, i {font-style: italic;}
ol, ul {list-style: none;}
li {list-style-type:none;}
:focus {outline: 0;}
a { cursor:pointer; outline: none; text-decoration:none;}

/* scrollbar appear  */
*,:after,:before {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -o-box-sizing:border-box;
  box-sizing:border-box;
}

hr {height:0;-moz-box-sizing:content-box;box-sizing:content-box;}
q {quotes:"201C" "201D" "2018" "2019";}
svg:not(:root) {overflow:hidden;}
sub, sup {
  position:relative;
  font-size:75%;
  line-height:0;
  vertical-align:baseline;
}

/* IE 8/9. */
audio, canvas, video {display: inline-block;}
button, input {line-height:normal;}
input[type="checkbox"], input[type="radio"] {
  padding:0;
  box-sizing:border-box;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */


/* -------------------------------------------------------------------- 
     ICONS PNG (no Fonts)
     -----------------------------------------------------------------------  */
[class^="ico-"]{
  background-image: url("../img/sprite.png");
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
  text-indent: -9999px;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;

  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;

}
[class^="ico-big-"]{
  width: 77px;
  height: 77px;
}
i [class^="ico-"]{
  display: block;
}
.ico-contact {background-position: left -114px;}
.ico-contact:hover {background-position: left -171px;}



[class^="icosvg-"]{
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  text-indent: -9999px;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;

  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;

}

/*
.icosvg-market{
  background: url("../img/ico-market-digit.svg") no-repeat center center;
}
.icosvg-innovation{
  background: url("../img/ico-innovation.svg") no-repeat center center;
}
.icosvg-visual{
  background: url("../img/ico-visual-white.svg") no-repeat center center;
}
*/



/* -------------------------------------------------------------------- 
     ICONS FONTS
     -----------------------------------------------------------------------  */
.fa {font-size: 1.8em;color: #222222;
}
[class^="icon-"]{
  color: #141414; display: inline-block;
}
.services [class^="icon-"]{
  margin-bottom: 20px; font-size: 2.5em;
}
.icon-share-2{ color: #b20038; font-size: 2.5em;
}
.icon-left-open-big{color: #FFFFFF;
}
a.btn.red:hover .icon-left-open-big{color: #141414;
}

/* -------------------------------------------------------------------- 
    ADDCLASS
    -----------------------------------------------------------------------  */
.relative{position:relative;}
.hidden{display:none;}
.v-hidden{visibility: hidden !important;}
.clear { clear:both; height:0px;}
.centered{margin: 0 auto 1.3em;float: none;text-align: center;}
.left {float: left !important;}
.right {float: right !important;}
.no-pad{padding:0;}
.no-space{padding-top: 0;margin-top:0;}
.topspace{padding-top: 1.3333%;}
.left0{margin-left: 0 !important;}
/*.opacity{ opacity: 0.4;filter: alpha(opacity=40);}*/
.overflow{overflow:hidden;}
.absolute{position: absolute;}
.no-color{color: #666666 !important;}
.pad-top{padding-top: 50px;}
.pad-bottom{padding-bottom: 50px;}
.txt-shadow{text-shadow:1px 1px 1px rgba(0, 0, 0, 0.6);}

.in{ opacity: 1 !important;filter: alpha(opacity=100);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.out{ opacity: 0 !important;filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.images-bg{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
}
.mb-show{display: none;
}
.img{ height: auto;
}
.no-border{border: none !important;
}

.hexagon { width: 100px; height: 55px; background: red; position: relative; }
.hexagon:before { content: ""; position: absolute; top: -25px; left: 0; width: 0; height: 0; border-left: 50px solid transparent; border-right: 50px solid transparent; border-bottom: 25px solid red; }
.hexagon:after { content: ""; position: absolute; bottom: -25px; left: 0; width: 0; height: 0; border-left: 50px solid transparent; border-right: 50px solid transparent; border-top: 25px solid red; }

.avoid-clicks {
  pointer-events: none !important;
}


/* Preloader ------------------ */
#preloader {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#FFFFFF;
  z-index:999999;
}
#status {
  width:200px;
  height:200px;
  position:absolute;
  left:50%;
  top:50%;
  background-image:url("../img/loader.png");
  background-repeat:no-repeat;
  background-position:center;
  margin:-100px 0 0 -100px; /* width and height divided by two */
}

.transit{
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/*  CENTERED (table / table-cell) (Parent must have fix height) */
.center-height {display: table;height: 100%;margin: 0 auto;
}
.center-height > * {display: table-cell;vertical-align: middle;
}
/* LOGO h1 text indent */
.tr {
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.absolute-overlay{
  position: absolute;
  top: 0;
  left: 0;
}
.v-align, .valign{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/*@media screen and (max-width:720px) {
  .v-align, .valign {
    position: absolute;
    left: 0;
    width: 100%;
  }
  }*/

.block {
  display: block;
}
.t-big {
  color: #141414;
  font-size: 100px;
  font-weight: bold;
  line-height: 100px;
  text-transform: uppercase;
  display: block;
}
.t-small {
  color: #141414;
  font-size: 28px;
  font-weight: 200;
  letter-spacing: 16px;
  text-transform: uppercase;
  display: block;
}
.job h2{
  font-weight:bold;
  font-size:3rem;
}
.alt-box{
  display: none;
}
@media screen and (max-width: 1024px){
  .alt-box{
    display: block;
  }
}

/* iPad */
.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 1;
  visibility: visible;
  display: none;
}

.bg-gradient:before {
  position: absolute;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk1JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjk1Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 95%, rgba(0,0,0,0.5) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(95%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 95%,rgba(0,0,0,0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 95%,rgba(0,0,0,0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 95%,rgba(0,0,0,0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 95%,rgba(0,0,0,0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-8 */
  content: " ";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* LAYER OVERLAY v2
* --------------------------------------- */

.layer{
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  text-align: center;
}

.layer.grey{
  background:rgba(0,0,0,0.55) ;
}

/* SHAPES ------------------------- */
.circle, .img-circle{
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  -o-border-radius: 50em;
  -ms-border-radius: 50em;
  border-radius: 50em;
}
.triangle-left {
  width: 50px;
  height: 100px;
  border-right: solid 50px rgb(200,30,50);
  border-bottom: solid 50px transparent;
  border-top: solid 50px transparent;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 100px solid #CCC;
  border-bottom: 50px solid transparent;
}
.triangle-up {
  width: 100px;
  height: 50px;
  border-bottom: solid 50px rgb(200,30,50);
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}
.triangle-bottom {
  width: 100px;
  height: 50px;
  border-top: solid 50px rgb(200,30,50);
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

/* TXT BG (put on span in h1 for bg text) */
.txtbg{
  padding:2px 9px;
  background-color:rgba(0,0,0,0.5);
  display: inline-block;
  position: relative;
  color:white;
}
.inner-pad{padding: 2em 3em !important;
}
.inner-pad-sm{padding: 1em 2em !important;
}
.inner-pad-lrg{padding: 3em 4.4em !important;
}

.bg-fixed{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}

/* VIDEO _ HOME -------- */
.video-bg{
  position:fixed;
  left:0;top:0;
  min-width:100%;
  min-height:100%;
  width:auto;
  height:auto;
  /*z-index:-100;*/
  z-index: 0;
  visibility: visible;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header video{
  max-width: inherit !important;
}

@media screen and (max-width: 600px) {
  .header .video-bg{
    display: none !important;
  }
  .header{
    background: url("../img/m-case-bg2.jpg") no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

/*#video{
  height: 100%;
  }*/


/* --------------------------------------------------------------------  
   MAIN TEXT
   -----------------------------------------------------------------------  */
html{
  color: #333;
  font-family: "Lato", Calibri,Arial,sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased ;
}
strong {font-weight:normal;}
article{
  font-size: 1.2em;
}
article p{
  color: #666666;
  padding: .5em 0;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  line-height: 1.65em;
  font-weight: 300;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Lato",Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
  color:#b20038;
}

h1{font-size: 1.6em;}
h2{font-size: 1.4em;}
h3{font-size: 1.2em;}
h4{font-size: 1.1em;}
h5{font-size: 0.95em;}
h6{font-size: 0.95em;;}

h1,h2{
  text-transform: uppercase;
  line-height:1.15em;
}
h1.large{font-size: 3em;}
h1.med{font-size: 2em;}

.txt-item-inner{
  text-align: center;
  padding: 2em;
  z-index: 1000;
}
.txt-item-inner h3{
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 4px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.txt-item-inner p > span{
  font-size: 1em;
  font-weight: 300;
  line-height: 25px;
  padding: 7px;
  font-style: italic;
  color: #333333;
}

/* LIGHTBOX TXT CONTENT -------- */
.vbox-inline h1, .vbox-inline h2, .vbox-inline h3, .vbox-inline h4,
.vbox-inline h5, .vbox-inline h6, .vbox-inline p, .vbox-inline ul li,
.vbox-inline a, .vbox-inline article{
  font-family: "Lato", Arial,sans-serif;
  font-weight: 300;
  line-height: 1.2;
}

/* --------------------------------------------------------------------
    LIST
    -----------------------------------------------------------------------  */
ul, li {
  font-weight: 300;
}
ul li{
  display: block;
}

/* --------------------------------------------------------------------  
    LINKS
    -----------------------------------------------------------------------  */

a.edit{
  right: 0;
  bottom: 0;
  z-index: 1111;
}
a.back-btn{
  left: 0;
  bottom: 0;
  z-index: 1111;
  font-size: 0.95em;
  padding: 0.95em;

}
a.edit, a.back-btn{
  font-weight: lighter !important;
}

/* ------------------------------------------------------------------  
    BUTTONS
    --------------------------------------------------------------------- */

.btn{
  display: inline-block;
  padding: 10px 15px;
  margin: 10px 0 0;
  border:3px solid #222222;
  cursor: pointer;
  font-size:1.25em;
  text-align: center !important;
  font-weight:normal;
  text-decoration: none;
  text-transform: uppercase;
  outline:none;
  color:#222222;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}

.btn i {margin-top: 2px;}
.btn.red {
  background: #b20038;
  color: #FFF;
  font-weight: normal;
  border: 1px solid #FFF;
}
.btn.red:hover {
  background: #b20038;
  color: #FFFFFF;
}
.btn.black {
  background: #000;
  color: #FFF;
  font-weight: normal;
  border: 1px solid #FFF;
}
.btn.black:focus, .btn.black:hover {
  background: #FFF; color: #000;
}
.btn.transparent {
  background: none;
  color: #FFF;
  font-style: normal;
  font-weight: normal;
}

.btn.transparent:focus, .btn.transparent:hover {
  background: transparent; color: #FFFFFF;
}
.btn.transparent.special {
  border-left: none;
  border-top: 2px solid #b20038;
  border-bottom: 1px solid #b20038;
  border-right: none;
  margin-top: 20px;
  margin-bottom: -15px
}
.btn.transparent.special i{
  font-style: normal !important;
  font-size: 0.85em;
  font-weight: lighter;
}

.btn.white{
  background: transparent;
  color: #121212;
}

/* --------------------------------------------------------------------  
   COLORS 
   -----------------------------------------------------------------------  */
html, body, .row-container{
  background-color:#FFFFFF;
}
h1,h2,h3,h4,h5,h6 {
  color:#141414;
}
a:hover, a:active, a:focus {color: #000000;}

ul, li, a{
  color: #141414;
}
[class^="b-"]{
  color: #FFFFFF;
  background-color: #FFFFFF;
}
.innerContent{
  background-color:#FFFFFF;
}
#menu li a {
  color: #FFF;
}
.navBtn {
  color: #FFF;
}
.red{
  color: #b20038;
}
.gold{
  color: #b20038 !important;
}
.srv-item a, .srv-item h3{
  color: #ffffff;
}
.works-item a, .works-item h3,
.srv-item a:hover h3, .srv-item a:hover p,
.works-item a:hover h3, .works-item a:hover p{
  color: white;
}
.black-bg{
  background-color:black;
}

.red-bg, .gold-bg{
  background-color:#b20038;
}
.white-bg, .white-bg .innerContent{
  background-color:white !important;
}
.white{color: white;}

/* -------------------------------------------------------------------- 
    ICONS  (for flexslider nav-arrows see plugins.css )
    -----------------------------------------------------------------------  */

/* ----------------------------------
GO-BOTTOM - container (.goto JS)  */
#headerscroll {
  bottom: 0;
  height: 10%;
  position: absolute;
  width: 100%;
  z-index: 999;
}

/* ICON scroll -------------------- */
.scroll {
  background: rgba(250, 250, 250, 0.1);
  display: block;
  position: relative;
  transition: all 0.5s ease 0s;
  width: 68px;
  height:36px;
  z-index: 2;
  -webkit-animation:  2s ease 0s normal none infinite scrollanimation;
  -moz-animation:  2s ease 0s normal none infinite scrollanimation;
  -o-animation:  2s ease 0s normal none infinite scrollanimation;
  animation:   2s ease 0s normal none infinite scrollanimation;
}
.scroll:before {
  background: rgba(250,250,250,1);
  content: "";
  position: absolute;
  width: 68px;
  height:36px;
  border-top: solid 18px rgba(172,0,56, 1) ;
  border-left: solid 34px transparent;
  border-right: solid 34px transparent;
  opacity: .3;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
.scroll:hover:before {
  transition: all 0.5s ease 0s;
  opacity: 1;
  background: rgba(250,250,250,1);
  border-top: solid 26px #b20038;
}
.scroll {
  bottom: 0;
  left: 50%;
  margin-left: -34px;
  position: absolute;
}
.scroll.black {
  background: rgba(0,0,0, 1);
}

/* ICON scroll ( WORK-DETAILS ) -------------------- */

.work-details .scroll{
  animation:none;
}
.work-details .row-description #headerscroll{
  width: 90%;
}
.work-details .row-description .scroll{
}
.work-details .row-description .scroll:before{
  background: rgba(250,250,250,1);
}

@media screen and (max-width:720px) {
  .work-details .row-description .info .valign{
    position: inherit !important;
    left: inherit;
    width: 100%;
    top: inherit !important;
    transform:none !important;
  }
}

/* ==================================================================
   MAIN STRUCTURE 
   ===================================================================== */
#page {
  width: 100%;
  position: relative;
}
#wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  /*overflow: hidden;*/
}
.l-line{
  display: block;
  position: fixed;
  left: 0;
  z-index: 111111;
  background: #b20038;
  width: 5px;
  height: 100%;
}

.container{
  position: relative;
  width: 100%;
  left: 0;

  -webkit-transition:  left 0.4s ease-in-out;
  -moz-transition:  left 0.4s ease-in-out;
  -ms-transition:  left 0.4s ease-in-out;
  -o-transition:  left 0.4s ease-in-out;
  transition:  left 0.4s ease-in-out;
}
.main-content {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.main-content.home {
  /* top: -16px;*/
}



h4{font-size: 1.7em;}

/* ============================================================
  SIDEBAR > MENU __ (PUSH-WRAPPER) 
  =============================================================== */

.open-sidebar {
  left: 240px;
}
#sidebar {
  position: absolute;
  left: -240px;
  background: #FFFFFF;
  width: 241px;
  height: 100%;
  z-index: 999999;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.contact #sidebar {
  z-index: inherit;
}


/* MENU ------------------------------ */
#sidebar .menu li a,
.menu-icon, .open .menu-icon{
  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
#sidebar .menu{
  position: fixed;
  top:38%;
  width: 241px;
  height: auto;
  bottom: 0;
}
#sidebar .menu .secondary-menu{
  position: absolute;
  bottom: 5px;
  width: 100%;
  top: auto;
  height: auto;
  min-height: 0;
}

#sidebar .menu .secondary-menu li a span {
  font-size: 13px;
}

/* Safari 

html[xmlns*=""] body:last-child #sidebar .menu {
  left: 0;
}
html[xmlns*=""]:root #sidebar .menu  {
  left: 0;
}
html:first-child #sidebar .menu  {
  left: 0;
}
*/

/*#sidebar .menu { (;left: 0;); }*/

/*
_::-moz-svg-foreign-content, :root #sidebar .menu {
  left: 0;
}*/

#sidebar .menu li {
  margin: 0;
  padding: 1px 0;
}
#sidebar .menu li a {
  display: block;
  padding: 10px 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #222222;
  text-transform: uppercase;
}
#sidebar .menu li a span{
  display: block;
  font-size: 20px;
  color: #222222;
  line-height: 1;
  opacity: 1;

  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  a ll 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
#sidebar .menu li:hover a span, #sidebar .menu li a.active span{
  color:#b20038;
}

/* HOVER MENU ---- FX  */
.menu li a.active span::before, .menu li a.active span::after,
.secondary-menu li a.active span::before, .secondary-menu  li a.active span::after {
  opacity:1;
  -webkit-transform:translateX(0px);
  -moz-transform:translateX(0px);
  transform:translateX(0px);
}
.menu li a span::before, .menu li a span::after,
.secondary-menu li a span::before, .secondary-menu li a span::after  {
  display:inline-block;
  opacity:0;
  -webkit-transition:-webkit-transform .5s,opacity .4s;
  -moz-transition:-moz-transform .5s,opacity .2s;
  transition:transform .5s,opacity .2s;
}
.menu li a span::before, .secondary-menu li a span::before {
  margin-right:20px;
  content:'[';
  -webkit-transform:translateX(20px);
  -moz-transform:translateX(20px);
  transform:translateX(20px);
}
.menu li a span::after, .secondary-menu li a span::after  {
  margin-left:20px;
  content:']';
  -webkit-transform:translateX(-20px);
  -moz-transform:translateX(-20px);
  transform:translateX(-20px);
}

.menu li a span:hover::before, .menu li a span:hover::after,
.menu li a span:focus::before, .menu li a span:focus::after,
.secondary-menu li a span:hover::before, .secondary-menu li a span:hover::after,
.secondary-menu li a span:focus::before, .secondary-menu li a span:focus::after {
  opacity:1;
  -webkit-transform:translateX(0px);
  -moz-transform:translateX(0px);
  transform:translateX(0px);
}

.menu li.follow-us{
  cursor: pointer;
}


/* MENU-TOGGLE ICON ---------------------- */
#sidebar-toggle {
  background: #FFFFFF;
  display: block;
  height: 106px;
  width: 66px;
  padding: 10px 7px;
  position: fixed;
  /*top: -49px;*/
  top: 0 !important;
  z-index: 9999;
  border: none;
  cursor: pointer;
}
#sidebar-toggle.out {
  opacity: 1 !important;
}
#sidebar-toggle.show-picto{
  top: 0 !important;
}
/*
#sidebar-toggle.slide-open{
 top: -51px !important;
}*/

#sidebar-toggle.delay, .delay{

}
.menu-icon {
  background-image: url("../img/menu-icon.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 24px;
  width: 28px;
  margin: 0 -16px 0 0;
  position: absolute;
  right: 50%;
  bottom: 14%;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.slide-open .menu-icon {
  background-position: 0 -25px;
  height: 25px;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.no-svg .menu-icon {
  background-image: url("../img/menu-icon.png");
}
#sidebar-toggle.open,
#sidebar-toggle.slide-open,
#sidebar-toggle:hover {
  opacity: 1 !important;
  filter: alpha(opacity=100)!important;
}

/* ToggleBTN-position ------ */
.picto-icon{
  display: block;
  height: 68px;
  position: absolute;
  left: 3px;
  top: 2px;
  width: 98%;

  -webkit-transition:  all 0.3s easeInOutExpo;
  -moz-transition:   all  0.3s easeInOutExpo;
  -ms-transition:  all 0.3s easeInOutExpo;
  -o-transition:  all 0.3s easeInOutExpo;
  transition:   all 0.3s easeInOutExpo;
}
.picto-icon img{
  margin: auto;
  max-width: 82%;
  padding: 10%;
}

#sidebar-toggle {
  left: 0;
}

/* -----------------------
END sidebar toggle */

/* LOGO - MENU-ICON -------- */
#sidebar-toggle, #sidebar .logo,
#sidebar.logo-appear .logo, .aw-trigger, .social-trigger{
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* HEADER > LOGO ------------ */
.logo {
  position: fixed;
  left: -300px;
  text-indent: -9999px;
  line-height: 0;
  width:220px;
  height:auto;
  top: 0;
  background: #FFFFFF;
  margin: 0;
}
.logo a img{
  display: block;
  /*padding: 15px;*/
  padding: 28px;
  margin: 0 auto;
  /*width: 60%;*/
  width: 100%;
}
img.alt-img{
  display: none;
}
.no-svg img.alt-img{
  display: block;
  max-width: 100%;
  height: auto;
}
.logo a img.alt-img{
  display: none;
}
.no-svg .logo a img.alt-img{
  display: block;
  max-width: 100%;
  height: auto;
}
.reveal {
  opacity: 1;
  filter: alpha(opacity=100);
  left: 0;
}
.showpannel{
  position: absolute;
  left: 0;
}


/*  HOME - SHARE --------------------------------------------- */

.social-trigger.toggle-share{
  background:#FFFFFF ;
  border: none;
  color: #b20038;
  display: block;
  height: 60px;
  width: 66px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  opacity: 1;
}

.toggle-share i.fa{
  font-size: 30px;
  padding-left: 4px;
  font-weight: normal;
  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
.toggle-share .icon-share-2{
  color: #222222;
  opacity: 0.8;
}
.toggle-share .icon-share-2:hover{
  opacity:1;
}

.toggle-share i.fa-thumbs-up{
  color: white;
}
.toggle-share i{
  background: url("../img/followus.svg") no-repeat scroll 2px center / cover ;
  color: #b20038;
  display: block;
  height: 50px;
  width: 50px;
}
.no-svg .toggle-share i{
  height: 45px;
  width: 45px;
  background: url("../img/followus.png") no-repeat scroll 2px center / cover ;
}


/* ============================================================================  
    BANNERS 
    ===============================================================================  */
.intro{
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.intro.sm{
  min-height: 220px;
}
.home #about .intro.sm{
  min-height: 480px;
}

.home .banner.intro.sm header h1.centered{
  margin: 0 auto;
  padding: 0;
}

/* _____________________________________________
   PARALLAX FX BG Banner on Scroll 
   ______________________________________________ */

.parallax {
  background-size: 100% auto;
  display: block;
  height: 360px;
  overflow: hidden;
  width: 100%;
}
#services .parallax{
  height: 440px;
}

.parallax .container {
  left: 0!important;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.parallax .parallax-background {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}
.parallax .parallax-background img {
  height: auto;
  position: relative;
  width: 100%;
}
.parallax .parallax-image {
  margin: 0 auto;
  padding-top: 165px;
  width: 300px;
}
.parallax .parallax-image img {
  height: auto;
  width: 100%;
}

.filter-gradient {
  position: relative;
}
.filter-gradient:after {
  background-size: 2000px auto;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.filter-gradient.gray:after {
  background: linear-gradient(to bottom, rgba(35, 37, 38, 1) 0%, rgba(65, 67, 69, 0.87) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.b-10.banner-overlay{
  height: 100%;
}
.banner-overlay, .b-10.banner-overlay, .workhome .b-10.banner-overlay,
#home-content .b-10.banner-overlay, .services .b-10.banner-overlay{
  /*background:rgba(0,0,0,0.55) url("../img/overlay-scan.png") repeat left top;*/
  background:rgba(0,0,0,0.35) ;
}

.flex-overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  /*background:rgba(0,0,0,0.65) url("../img/overlay-scan.png") repeat left top;*/
  background:rgba(0,0,0,0.55) ;
}
.workhome .banner, #home-content .banner, .services .banner{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.workhome .banner header, #home-content .banner header, .services .banner header{
  padding: 6% 0 6em;
}

/* BANNERS BG ANIMATION ------------------ */
.animbg_01 {
  animation: 110s linear 0s normal none infinite running animatedBackground;
}


/* BANNERS ____ ABOUT --------------- */
.services .parallax header h1{
  text-align: center;
}

#services .parallax.tall{
  height: 440px;
}

/* BANNERS ____ MAIN STYLES & WORK-DETAILS  --------------- */
.work-details .banner{
  height: 50%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.banner header{
  letter-spacing: -1px;
  padding: 6% 0 6em;
}
.work-details .banner header{
  padding: 14% 0 6em;
}
.banner header h1, .parallax header h1{
  padding: 0 1.55em;
  font-size: 4.5em;
  font-weight: lighter;
  line-height: 1.2;
  max-width: 1200px;
  /*color: #b20038;*/
  color: rgba(250, 250, 250, 0.35);
}
.banner header h1{
  padding: 0 1.55em;
}
.parallax header h1{
  padding: 2em 1.55em;
}

.home .parallax:first-child header h1{
  padding: 1.6em 1.55em 0;
  margin: 0 auto 0.5em;
}
.home .parallax:first-child header h2{
  padding: 0 1.55em 1em;
  width: 66%;
  line-height: 1.4em;
  color: #ffffff !important;
  opacity: 0.9 !important;
}

.banner header h1 span, .parallax header h1 span{
  display: block;
  font-size: 32%;
  font-weight: lighter;
  padding-top: 0.325em;
  line-height: 1.5em;
  color: #FFFFFF;
}
.banner.white-bg h1{
  color: #141414;
  font-size: 3.5em;
}
.banner.white-bg h1 span{
  color: #333333;
}
.slides h1,  .slides h1 span, .slides h2{
  display: block;
  font-size: 2.5em;
  line-height: 1.2;
  padding: 0 1.55em;
  font-weight: lighter;
  width: 100%;
  color: #FFFFFF;
}

.fluid-width-video-wrapper video:hover, .video-wrapper video:hover, .fluid-width-video-wrapper:hover,
#player:hover{
  cursor: url("../img/cursor-play.png") no-repeat left top ;
}

/* ============================================================================  
    HOME
    ===============================================================================  */

/* body class="home" */
.home .full-slider .intro{
  min-height: 480px;
}

/* BIG LOGO ------------------ */
.flip-container {
  background-color:transparent;
  height: 200px;
  width: 230px;
  left: 50%;
  line-height: 0;
  margin-left: -115px;
  margin-top: -100px;

  position: absolute;
  text-indent: -9999px;
  top: 50%;
  z-index: 99;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
}
.logoBig {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 200px;
  width: 230px;
  margin-left: -115px;
  margin-top: -100px;
  text-indent: -9999px;
  line-height: 0;
  z-index: 99999;

}
.no-svg .logoBig {
  background: #FFFFFF url("../img/logo.png") no-repeat center center;
}
.logoBig a{
  display: block;
  /*width:250px;*/
  height: 190px;
  width: 230px;
}

/* OVERLAY -------------------------------- */
.overlay-main, .overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  /*background:rgba(0,0,0,0.55) url("../img/overlay-scan.png") repeat left top;*/
  background:rgba(250,250,250,0.55);
  z-index: 801;

  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.overlay{
  z-index:1;
}

.overlay-home.appear{
  /* display: none;*/
}
.overlay-main{
  background: transparent !important;
}


/* OVERLAY SHARE ----------------------------- */
.overlay-share{
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  z-index: 99999;
  opacity: .9;
  filter: alpha(opacity=90);
  visibility: visible;
  background:rgba(250,250,250,0.95);
}

.overlay-share.appear{
  z-index: 99999;
  opacity: .9;
  filter: alpha(opacity=90);
  visibility: visible;

}
.overlay-share .share-box{
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -141px;
  margin-top: -30px;
  position: fixed;
  top: 50%;
  width: 282px;
}

.overlay-share .social li a i.fa, .social li a i{
  color:#b20038 ;
}


@media screen and (max-width : 480px) {
  /* .overlay-share .share-box {
       display: block;
       height: 60px;
       left: 100%;
       position: fixed;
       top: 30%;
       width: 45px;
       }*/
}

#sidebar-toggle.appear{
  z-index: 111111;
}
.toggle-share.appear .icon-share-2{
  color: #555555;
}
.social-trigger.hide, .social-trigger{
  opacity: 0 !important;
  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}

.social-trigger.in{
  opacity: 1 !important;
}
.social-trigger.out{
  opacity: 0 !important;
}


/* HOMESHARE LINKS ----------------------------- */
.share-box.homeshare{
  bottom: 1%;
  position: absolute;
  right: 1%;
  z-index: 111111;
}

/* HOMESHARE LINKS CENTERED -----------*/
/*.share-box.homeshare {
    bottom: 7%;
    left: 50%;
    margin-left: -133px;
    position: absolute;
    z-index: 111111;
    }*/

.share-box.homeshare .social li{
  margin-left: 0;
}
.share-box.homeshare .social li a{
  font-size: 22px;
  width: 40px;
}

.overlay-transp{
  display: block;
  position: absolute;
  /*height: 100%;*/
  height: 80%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

/* BIG LOGO __ ANIM - FLIP ROTATE 3D 
-------------------------------------------------- */

.panel .front {
  background: #FFFFFF url("../img/logo.svg") no-repeat center center;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: inherit;
  height: inherit;
  text-align: center;

  /*   -webkit-transform: rotateX(0deg) rotateY(0deg);
        -moz-transform: rotateX(0deg) rotateY(0deg);
             transform: rotateX(0deg) rotateY(0deg);

     -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
             transform-style: preserve-3d;

     -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;*/

  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.panel .front img{
  max-width: 100%;
}

.no-svg .panel .front  {
  background: rgba(0,0,0, .85) url("../img/logo.png") no-repeat center center;
}

.overlay-home{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: #141414;
  z-index: 0;
  opacity: 0;
  background: rgba(0,0,0,0);
  filter: alpha(opacity=0);

  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}


.overlay-home.appear, .overlay-main.appear{
  background: rgba(0,0,0,.65) !important;
  opacity: .55 !important;
  filter: alpha(opacity=55) !important;
  z-index: 888 !important;

  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.secondary-menu:hover .overlay-home{
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
/*
.panel:hover .overlay-home{
  background: rgba(0,0,0,.75);
  opacity: 1;
  filter: alpha(opacity=100);
}
*/

.flexslider .slides li{
  z-index: 0 !important;
}

.m-bg{
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}


/* ====================================================================  
    SERVICES 
    =======================================================================  */
.services .inner-pad{
  padding:1.5em 4em !important;
}

.services .txt-item-inner p > span{
  font-style: normal;
  font-size: 0.9em;
}
.services hr {
  background:#e8e8e8;
  border: none;
  box-sizing: content-box;
  height: 2px;
  margin: 0 auto;
  width: 40%;
  display: block;
}
.slides h1{
  color: white;
  opacity: 1;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1111;
}
.services .fa {
  margin-left: -40px;
  padding: 15px 10px;
  position: absolute;
  top: -60px;
  width: 80px;
}

/* SERVICES-ITEM - HOME ------------------------------- */
.srv-item {
  margin: 0 ;
  padding: 0 ;
  width: 100% ;
  background-color: #111111;
  -webkit-font-smoothing:antialiased;
}

.srv-item a{
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 100;
  text-align: center;
  -webkit-font-smoothing:antialiased;
}

/* HOVER SERVICES-ITEM FX --------------- */
.srv-item a:hover{
  background: rgba(178, 0, 56, 0.75);
  -webkit-transition: background .3s ease-in-out;
  -moz-transition: background .3s ease-in-out;
  -ms-transition: background .3s ease-in-out;
  -o-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
}

.srv-item img{
  opacity: 0.75;
  -webkit-transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -ms-transition: transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
}
.srv-item:hover img{
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}

.srv-item h3{
  opacity:1;
  text-shadow:1px 0 1px rgba(0,0,0, .5);
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.srv-item a, .srv-item h3{
  font-weight: 400;
}
.srv-item a:hover img{
  opacity: 1;
}

.partners > img{
  opacity: .8;
}
.partners:hover > img{
  opacity: 1;
}
.partners > img{
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

/* ====================================================================  
    ABOUT
    =======================================================================  */

.time-bg{
  background: #FFFFFF url("../img/indoor4.jpg") no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
}
.time-bg .overlay{
  background: rgba(0, 0, 0, 0.75) ;
}

/* ------------------------------------------------- 
    TIMELINE GRID - ABOUT
    ----------------------------------------------------  */

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
  padding: 50px 0 80px;
  z-index: 111;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  background-color: #b20038;
  border-radius: 5px;
}
.timeline>li {
  position: relative;
  margin-bottom: 50px;
  min-height: 50px;
}
.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}
.timeline>li:after {
  clear: both;
}
.timeline>li .timeline-panel {
  float: right;
  position: relative;
  width: 100%;
  padding: 0 50px 0 100px;
  text-align: left;
}
.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline>li .timeline-image {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  border: 7px solid #b20038;
  text-align: center;
  color: #141414;
  background-color: #FFFFFF;
  overflow: hidden;
  -webkit-transform: rotate(45deg) scale(.75);
  -ms-transform: rotate(45deg) scale(.75);
  transform: rotate(45deg) scale(.75);

  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.timeline>li .timeline-image a, .timeline>li .timeline-image h4{
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg) ;
  transform: rotate(-45deg);
}
.timeline>li .timeline-image img{
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg) ;
  transform: rotate(-45deg);
}

.timeline>li .timeline-image:last-child{
  background-color:#b20038;
  text-transform: uppercase;
}
.timeline>li .timeline-image:last-child h4{
  color: white;
}
.timeline>li .timeline-image h4 {
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
}
.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline>li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4 {
  margin-top: 0;
  font-size: 1.8em;
  font-weight: normal;
}
.timeline .timeline-heading h4.subheading {
  text-transform: none;
}
.timeline .timeline-body>p,
.timeline .timeline-body>ul {
  margin-bottom: 0;
  color: rgba(250, 250, 250, 0.9);
}
.timeline-image.link{
  padding: 1.2em 1em;
}


@media(min-width:768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline>li {
    margin-bottom: 100px;
    min-height: 100px;
  }
  .timeline>li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline>li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline>li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
  }
  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media(min-width:992px) {
  .timeline>li {
    min-height: 150px;
  }
  .timeline>li .timeline-panel {
    padding: 0 50px 20px;
  }
  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline>li .timeline-image h4 {
    margin-top: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media(min-width:1200px) {
  .timeline>li {
    min-height: 170px;
  }
  .timeline>li .timeline-panel {
    padding: 0 50px 20px 100px;
  }
  .timeline>li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline>li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 100px 20px 20px;
  }
}


/* ====================================================================  
    WORK
    =======================================================================  */

/* 
  ISOMENU - ISO-FILTER NAV 
  (Open Pannel FadeInUp) (for filter see plugin.css and js )
  ------------------------------------------------------------------------- */
div.isomenu{
  width: 200px;
  height: auto;
  z-index: 9999;
  position: absolute;
  overflow: visible;
  right: 1px;
  top: 0;
}
.menu2, .menu2 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu2 {
  float: right;
  padding:0;
  margin-top: 1px;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  cursor:default;
  background:#141414;
  zoom:1;
}
.menu2:before,
.menu2:after {
  content: "";
  display: table;
}
.menu2:after {
  clear: both;
}
.menu2 li {
  float: left;
  position: relative;
}
.menu2 li:hover > a {
  color: #fafafa;
  cursor: pointer;
}
.menu2 li > a {
  text-decoration: none;
}
.menu2 li > a.nav-icon:hover {
  cursor: default !important;
}

.nav-icon{
  height: 57px;
  width: 57px;
  display: block;
  background-color: #FFFFFF;
}
.nav-icon i{
  background: url("../img/filter2.svg") no-repeat scroll center center / cover ;
  display: block;
  height: 32px;
  width: 32px;
  position: relative;
  top: 16px;
  left: 13px;
}
.no-svg .nav-icon i{
  background: url("../img/filter2.png") no-repeat scroll center center / cover ;
}
.menu {
  left: -300px;
}

/*  HIDDEN UL MENU --- */
.menu2 ul {
  margin: 20px 0 0 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -123px;
  top:  57px;
  z-index: 1;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
  z-index: 9999;
}
.menu2.web li:hover > ul, .show-cat > ul{
  opacity: 1;
  visibility: visible;
  margin: 0;
}
.menu2 ul li {
  float: none;
  display: block;
  border: 0;
  cursor: pointer;
}
.menu2 ul li:last-child {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.menu2 ul a {
  width: 180px;
  display: block;
  white-space: nowrap;
  float: none;
  text-transform: none;
  text-align: center;
  color: #b20038;
  font-size:0.95em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding:12px  0.6em;
  text-transform: uppercase;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.menu2 ul a.active, .menu2 ul a:hover, .iso-filter-nav a.current,
.menu2 ul a:active, .menu2 ul a:focus, .menu2 ul a:hover {
  background: transparent !important;
  color: #111111 !important;
}

.iso-filter-nav{
  background: #FFFFFF !important;
}


/*.menu2:hover a.nav-icon, .menu2:hover a.nav-icon i.fa{
 background: #FFF;
  color: #141414 !important;
  }*/

/*
.menu2 ul li:first-child > a:after{
  content: '';
  position: absolute;
  right: 22px;
  top: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0,0,0,0.3);
  }*/

/*
.menu2 ul li:first-child a.active:after{
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFF;
  }*/


/* Mobile */
.menu-trigger {
  display: none;
}
.menu2 li:hover > .no-transition {
  display: block;
}

/* WORK-ITEM --------------------------------- */
.works-item {
  margin: 0 ;
  padding: 0 ;
  width: 100% ;
  overflow: hidden;
  background-color: #141414;
}
.works-item a{
  opacity: 0;
  filter: alpha(opacity=0);
  display: block;
  background-color: #141414;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 100;
  text-align: center;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}


/* HOVER WORK-ITEM FX --------------- */
.works-item a:hover{
  cursor: pointer;
  opacity:0.8;
  filter: alpha(opacity=80);
  background-color: rgba(178, 0, 56, 0.75);
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
/* .info a:hover{
    cursor: url("../img/plus.png") 40 40, crosshair;
    -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
         -ms-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
             transition: all .3s ease-in-out;
             }*/

.works-item img{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  background-size: cover;
  background-position: center center;

  -moz-transform: scale(1.01, 1.01);
  -webkit-transform: scale(1.01, 1.01);
  -o-transform: scale(1.01, 1.01);
  -ms-transform: scale(1.01, 1.01);
  transform: scale(1.01, 1.01);

  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.works-item:hover img{
  opacity: 1;
  -moz-transform: scale(1.08, 1.08);
  -webkit-transform: scale(1.08, 1.08);
  -o-transform: scale(1.08, 1.08);
  -ms-transform: scale(1.08, 1.08);
  transform: scale(1.08, 1.08);
}
.works-item .txt-item-inner p > span{
  font-size: 1.25em !important;
  color: #FFFFFF;
}


/* FILTER WORK PANEL 
--------------------------------------------------------------- */
.wfilter-trigger{
  height: 57px;
  width: 57px;
  display: block;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background: #FFFFFF;
}
.wfilter-trigger i.fa{
  color: #b20038;
  font-size: 30px;
  padding:15px 17px;
  font-weight: normal;
}
.toggle-wfilter{
  background: #FFFFFF;
  display: block;
  height: auto;
  height: 100%;
  width: 220px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: -220px;
}
.toggle-wfilter > ul{
  padding: 12px;
  position: absolute;
  top: 80px;
  width: 100%;
}
.toggle-wfilter > ul li{
  display: block;
}

/*  FILTER WORK PANEL ACCORDION SUBMENU 
--------------------------------------------------------------- */

/*.hidden{*/
  /*display:none!important;*/
/*}*/

.toggle-container{
  width: 25%;
  line-height: 1.2em;
}
.parents-toggle{
  position: relative;
  padding: 10px;
  color: white;
}
.parents-toggle > a{
  position: relative;
  padding: 10px;
  color: white;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;
}
.workhome .parents-toggle > a{
  color: #111111;
  line-height: 1.25em;
}
.workhome .parents-toggle > a:hover{
  color: #b20038;
}
.content-toggle > ul li{
  padding: 8px 10px;
  display: block;
  height: auto;
  background: transparent;
  line-height: 1.2em;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
}
.content-toggle > ul li a{
  color: #555;
}
.parents-toggle > a.active, .content-toggle > ul li a.current,
.content-toggle > ul li a:hover, .content-toggle > ul li a:focus,
.content-toggle > ul li a:active, .content-toggle > ul li a.active{
  color: #b20038 !important;
  background: transparent !important;
}


/* ====================================================================  
    WORK DETAILS 
    =======================================================================  */

/* SHARE ---------------------------- */
.share-btn{
  border: none;
  background: transparent;
  width: 55px;
  height: 55px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.contact .share-btn, .contact .share-toggle{
  top: 0;
}
.share-btn i.fa{
  font-size: 30px;
  color: #141414;
  font-weight: normal;
  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
.share-btn:hover i.fa{
  opacity: 0.7;
}
.share-toggle{
  height: 40px;
  width: 190px;
  overflow: visible;
  z-index: 1001;
  position: absolute;
  opacity: 0;
  /* z-index: 999999;*/
  background: #FFFFFF;

  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
.share-toggle.open-share{
  opacity: 1;
  z-index: 999999;
}
.share-toggle.open-share li{
  display: block;
  float: right;
  padding: 11px 3px;
}

ul.share{
  width: 100%;
  height: 40px;
  display: block;
  padding: 0;
}
.share li{
  display: inline;
  float: right;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 4px;
  font-size: 0.4em;
  padding: 11px 3px;
}
.share li a{
  width: 50px;
  display: block;
  /*font-size: 30px;*/
  font-size: 0.4em;
  text-align: center;
}


.video video{
  display: block;
}
.video .video-screenshot{
  display: none;
}

.cta-contact{
  text-transform: uppercase;
  font-weight: 300;
  position: relative;
  color: #141414 !important;
  top:15px;
}
.cta-contact:hover{
  color: #997e52;
}
.cta-contact:after{
  background: #997e52;
  bottom: -10px;
  left: 0;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  width: 20px;
  -webkit-transition:  width 0.3s ease-in-out;
  -moz-transition:   width  0.3s ease-in-out;
  -ms-transition:  width 0.3s ease-in-out;
  -o-transition:  width 0.3s ease-in-out;
  transition:   width 0.3s ease-in-out;
}
.cta-contact:hover:after{
  width: 100%;
}

/* WORK DETAILS > NEXT WORK NAV ------------------- */
nav.nav-slide{
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  outline: none;
  text-align: left;
  z-index: 1000;
}
nav a.next {
  right: 0;
}
nav a .icon-wrap {
  display: block;
  margin: 0 auto;
  padding: 0;
}

/* WORK-DETAILS > NAV ARROW - Slide out ------------ */
.nav-slide .icon-wrap {
  position: relative;
  z-index: 100;
  display: block;
  padding: 0;
  opacity: 0.85;
  background-color: #b20038;

  -webkit-transition:  background-color 0.3s ease-in-out;
  -moz-transition:   background-color  0.3s ease-in-out;
  -ms-transition:  background-color 0.3s ease-in-out;
  -o-transition:  background-color 0.3s ease-in-out;
  transition:   background-color 0.3s ease-in-out;
}
.nav-slide .icon-wrap:hover,
.nav-slide:hover .icon-wrap {
  opacity: 1;
}
.nav-slide div {
  position: absolute;
  top: 50%;
  padding: 10px 100px;
  background-color: #FFFFFF;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.nav-slide a.next div {
  right: 0;
  padding-left: 120px;
  text-align: right;
  -webkit-transform: translateY(-50%) translateX(100%);
  -moz-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
}
.nav-slide a.next div img {
  background: #FFFFFF;
  opacity: 1;
}
.nav-slide h3, .nav-slide p {
  position: relative;
  margin: 0;
  padding: 20px 0;
  color: #222222 !important;
  white-space: nowrap;
  font-weight: 300;
  font-size: 1.3em;
  line-height: 1.5;
}
.nav-slide div h3 span {
  display: block;
  color: #CCC;
  font-style: italic;
  font-size: 65%;
}
.nav-slide img {
  position: absolute;
  top: 0;
  height: 100%;
}
.nav-slide a.next img {
  left: 0;
}
.nav-slide a:hover div {
  -webkit-transform: translateY(-50%) translateX(0);
  -moz-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
}

.icon-wrap:before{
  content: ' ';
  width: 40px;
  height: 60px;
  border-left: solid 25px #FFFFFF;
  border-bottom: solid 42px transparent;
  border-top: solid 42px transparent;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
.icon-wrap:hover:before{
  border-left: solid 32px #FFFFFF;
  border-bottom: solid 42px transparent;
  border-top: solid 42px transparent;
  transition: all 0.5s ease 0s;
}

.nav-slide a{
  line-height: 1.7em !important;
}


/* WORK DETAILS > ROW-DESCRIPTION RED ------------------ */
.row-description .txt-item-inner{
  background-color: #b20038;
  padding: 2em 1em !important;
  color: #FFFFFF;
}
.row-description .txt-item-inner ul li,
.row-description .txt-item-inner > p,
.row-description .txt-item-inner a{
  color: white;
  text-align: left;
  font-size:0.8em;
  text-transform: uppercase;
}
.row-description .txt-item-inner ul{
  margin-bottom: 1em;
}
.row-description .txt-item-inner ul li{
  padding: 0.3em 1.5em;
  display: block;
  text-transform: capitalize;
  line-height: 1.15em;
  font-size: 0.85em;
  color: rgba(250, 250, 250, 1);
}
.row-description .txt-item-inner ul li b, .row-description .txt-item-inner ul li strong{
  text-transform: uppercase;
  display: block;
  font-size: 0.95em;
  font-style: normal;
  margin-bottom: 6px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}

body.3lp .row-description h2, .row-description h2{
        font-size: 2.4em;
        font-weight: 300;
        color: #141414;
      }
.row-description .txt-item-inner .info-list li{
  font-style: italic;
  color: #FFFFFF;
  text-transform: capitalize;
}
.row-description .categories li a{
  font-size:1em;
  padding: 0;
}
.row-description .info-list li strong{
  font-style: 300;
  text-transform: uppercase;
}
.row-description .txt-item-inner .btn{
  display: none;
  bottom:15px;
  left: 25%;
}
.row-description .btn.back{
  bottom: 15px;
  right: 3em;
  display: none;
}

/* ====================================================================  
    CONTACT 
    =======================================================================  */

.contact .banner h1{
  width: 40%;
  color: #141414;
  font-size: 6em;
  line-height: 0.6em;
}
.contact .banner header{
  padding: 15% 0 0;
}
.contact .banner header h1 span{
  display: inline-block;
  color: #b20038 !important;
}
.right-box{
  background-color: #FFFFFF;
}
.absolute-indent{
  left: -9999px;
  background: transparent;
  color: transparent;
  text-indent: -9999px;
  font-size: 0.01em;
  position: absolute;
  height: 0;
}
#form-container .b-2.right-box.adress{
  padding: 0 !important;
  height: 67vh;
  min-height: 400px;
  float:right;
  top: 0;
  position: relative !important;
}
#form-container .b-2.right-box.adress .txt-item-inner.valign{
  position: absolute;
  top: 50%;
  left:50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#form-container .b-2.right-box.adress .social {
  margin-bottom: 0;
  padding-bottom: 0;
}


#form-container .checkbox-wrap .inline input{
    position: absolute;
}


/* ----------------------------------- 
    CONTACT > MAPS
    --------------------------------------  */
#map{
  width: 100%;
  height: 33%;
}
#gmap-popup{
  max-width: 250px;
  padding: 20px;
}
.contact-header{
  position: absolute;
  z-index: 999;
  left: 82px;
}
.contact-header.intro{
  width: 30%;
  min-height: 200px;
}
.contact-header.banner h1{
  width: 105%;
  padding: 0 0.5em;
  text-shadow: 2px 1px 3px #ffffff;
}
.contact-header.banner header{
  padding: 25% 0 0;
}
.gmnoprint{
  display: none;
}
#gmap-popup .gm-style-iw > *{
  overflow: hidden !important;
}
#gmap-popup h3{
  margin-bottom: 8px !important;
  text-transform: uppercase;
  font-weight: 300;
}
#gmap-popup h3, #gmap-popup ul li a{
  color: #141414;
  display: block;
  margin: 0;
  padding: 4px 0;
}

/* INFOS - ADRESS 
------------------------------------------ */
.adress .inner, .adress p, .adress{
  text-align: center !important;
  color: white;
  text-transform: inherit !important;
}
.adress .inner a:hover, .adress a:hover{
  color:#b20038;
}
.adress h3{
  text-transform: uppercase;
  font-size: 1.8em;
  color: #FFFFFF;
}
.adress p {
  font-family:"Lato", Arial, Helvetica, sans-serif;
  font-size:1.2em;
  text-align:left;
  line-height:1.2em;
  margin:8px auto;
  padding:0;
  font-weight: lighter;
  text-transform:uppercase;
}
.adress p a{
  display:inline-block;
  color:#FFF;
  text-decoration:underline;
  padding:0 6px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
.adress p a:hover{
  color:#141414;
}
a.mail{
  text-transform:lowercase;
}

/*  SOCIAL ICONS 
----------------------------------- */

.adress ul.social{
  display: block;
  height: auto;
  margin: 20px auto;
  overflow: hidden;
  padding: 12px 0;
  width: 280px;
}
.social li{
  display: inline;
  float: left;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 4px;
}
.social li a{
  width: 50px;
  display: block;
  font-size: 30px;
  text-align: center;
  padding: 10px;
}

.social li a i.fa, .social li a i{
  -webkit-transition:  all 0.3s ease-in-out;
  -moz-transition:   all  0.3s ease-in-out;
  -ms-transition:  all 0.3s ease-in-out;
  -o-transition:  all 0.3s ease-in-out;
  transition:   all 0.3s ease-in-out;
}
.social li a:hover i.fa-facebook{
  color: #3f67c0;
}
.social li a:hover i.fa-twitter, .social li a:hover i.icon-vimeo-2{
  color: #35b5eb;
}
.social li a:hover i.fa-linkedin{
  color: #0274B3;
}
.social li a:hover i.fa-google-plus{
  color: #DF4B38;
}
.social li a:hover i.fa-youtube{
  color: #DE1A2A;
}

.adress .social li a, .adress .social li a i{
  color: #FFFFFF;
}

/* --------------------------------------------------------------------
    FORMS
    -----------------------------------------------------------------------  */
.inline{
  display: inline;
  float: left;
}
.b-2.form_wrapper{
  width: 49%;
  background: #FFFFFF !important;
}
.form_wrapper {
  height: auto;
  overflow: hidden;
  padding: 0;
  margin-top:25px;
  text-align: left;
}

.row.bottom .b-2.form_wrapper{
  width: 58%;
}

.row.bottom .b-2.right-box.adress{
  width: 42%;
}

/* form content ------  */
.box.form_wrapper header h2{
  float: none;
  text-align: center;
}
.block {display: block; margin: 10px auto;}
.block.hide {display: none;}
.alert {display: block;}
.alert.error {color: #555;}
.alert.hide {display: none;}

label {display: none;}

input, select, textarea{
  background: #FFFFFF;
  display: inline-block;
  *display: block;
  border: 0;
  *border: none;
  padding: 8px 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #636363;
  width: 100%;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  color: #141414;
  font-size:1em;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  text-indent: 10px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
/*input, textarea {
  color:#141414;
  }*/
textarea{
  min-height:80px;
  max-height:120px;
  width: 100%;
  padding: 4px 0;

}

.no-boxshadow input, .no-boxshadow textarea {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #555;
  color: #FFFFFF;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}
input:hover, textarea:hover {
  color:#141414;
}
input:focus, textarea:focus {
  border-bottom: 1px solid #b20038 !important;
  color: #141414;
}

textarea { margin-top: 1em;}
select
{
  background: none;
  border: 0;
}
form input.error {background-color:#FFFFFF; border-bottom: 1px solid #b20038;}
form textarea.error {background-color:#FFFFFF; border-bottom: 1px solid #b20038;}
form select.error {color: #333;}
input[type="checkbox"], .checkbox input {width: auto; background: none;}
input[type="submit"], button {cursor: pointer;}
.checkbox label {display: inline;}
select>option:first-child {opacity: .7;}

::-webkit-input-placeholder {
  color: #222222;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  opacity: 1;
}

:-moz-placeholder {
  color: #222222;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  opacity: 1;
}
::-moz-placeholder {
  color: #222222;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #222222;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  opacity: 1;
}
.form_wrapper form.classic {
  margin: 30px auto 0 !important;
  width: 80%;
  overflow: hidden;
  padding: 5px;
}

.form_wrapper form.classic h2, .form_wrapper form.classic h2 + ul li{
  color: #bb0000;
  padding-bottom: 10px;
}
.form_wrapper form.classic h2 + ul li{
  padding-left: 15px;
  position: relative;
}
.form_wrapper form.classic h2 + ul li:before{
  content: "";
  background: #bb0000;
  width: 8px;
  height: 2px;
  display: block;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 6px;
}

.form_wrapper form.classic>p {
  padding: 0.85em 0 0.85em 0;
  margin:0;
  display: block;
  text-align: center;
  min-height: 36px;
}
.form_wrapper form.classic>.mid {width: 48.5%;}
.form_wrapper form.classic>.left {float: left;}
.form_wrapper form.classic>.right {float: right;}
.form_wrapper form.classic>.full {width: 100%;}
.form_wrapper form.classic>.tocenter  {text-align: center;}
.form_wrapper form.classic>.clear  {clear: both; display: block;}
.form_wrapper form .alert{
  color:#F25868;
  margin-right: 0;
  text-align: left;
}
.form_wrapper .tehend { padding-top: 40px;}
.form_wrapper form .alert.hide {display: none;}

/* SELECT STYLED -------------- */
.select-style {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 95%;
  z-index: 99;
  background:#FFFFFF;
  color:  #222222;
  text-align: center;
  height: 32px;
}
.select-style select {
  width: 108%;
  color: #222222;
  background: #FFFFFF;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.9em;
  text-align: left;
}
select:checked, select:active, option:checked, option:active {
  border: 2px solid #b20038;
}
.select-style option{
  background:#FFF;
  padding: 5px 2px;
  text-transform: uppercase;
  box-shadow: 0;
}
.select-style option:hover{
  background:#222;
  color: white;
}

.select-style i{
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -6px;
  width: 16px;
  height: 12px;
}
.select-style i.ico-select{
  background: url("../img/select-arrow.png") no-repeat left top;
  display: block;
  height: 24px;
  width: 28px;
}

/* FORM - BTN --------------- */
.form_wrapper input[type="submit"], .form_wrapper .btn{
  width: 100%;
  padding: 8px 0;
  margin: 10px 0 80px;
  border:3px solid #FFFFFF;
  cursor: pointer;
  font-size:1.25em;
  font-weight:300;
  text-decoration: none;
  outline:none;
  color:#222222;
  background: #FFFFFF ;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition:  all 0.2s ease;
}

.btn:hover{
  background-color:#b20038;
  color:#141414;
}
.submit{
  width:100%;
}
.btn.smaller{
  display: block;
  width:80%;
  margin:25px auto;
}
.form_wrapper input[type="submit"], .form_wrapper .btn{
  margin-top: 30px;
  border: 3px solid #141414 !important;
}
.form_wrapper .btn:hover{
  border: 2px solid #b20038 !important;
  color: #141414;
  background-color: white;
}
.form_wrapper span.captch{
  float: left;
  display: block;
  margin-top: 9px;
}

h2.success{
  display: inline-block;
  left: 82px;
  position: absolute;
  text-align: left;
  top: 65px;
  width: calc(100% - 164px);
}

.jobs h2.success{
  display: inline-block;
  position: relative;
  text-align: left;
  top: 0;
  width: 80%;
  margin: 0 10%;
}

@media screen and (max-width: 480px) {
  .jobs h2.success{
    top: auto;
    left: 0;
    bottom: auto;
  }
}



/* INFOS TXT FORM 
-------------------------- */
.infos{
  margin: 70px 35px 20px;
}
.infos h3{
  font-size:1em;
  text-align:left;
  line-height:1.2em;
  margin:8px auto;
  padding:0 0 20px 0;
}
.infos p{
  font-size:1.3em;
  text-align:left;
  line-height:1.2em;
  margin:8px auto;
  padding:10px 0;
}

/* --------------------------------------------------------------------  
    IMAGE SWITCH for mobile
    -----------------------------------------------------------------------  */
.mb-img{display: none;
}
.lrg-img{display: block;
}

/* --------------------------------------------------------------------
    IE9 - IE10 TARGET
    -----------------------------------------------------------------------  */

/* target IE9 only (use ie9 class or other classes) ------------ */
@media all and (min-width:0\0) and (min-resolution: .001dpcm){
  .ie9{

  img, video{
    width: 100%;
  }
  .logo a img{
    max-width: 95%;
  }
  .picto-icon img{
    width: 82%;
  }
  label {
    display: block;
    color: #222222;
    text-align: left;
  }

}
}
/* target IE10 only (to put before class ie10 or other classes) ------------ */
html[data-useragent*='MSIE 10.0'] .ie10{

img, video{
  width: 100%;
}
.logo a img{
  max-width: 95%;
}
.picto-icon img{
  width: 82%;
}
.front{
  background: url("../img/logo.png") no-repeat center center !important;
}
}

.ie .picto-icon img{
  width: 82%;
}


/* IE 10 & 11 (and older) */
@media screen and (min-width:0\0) {
  .picto-icon img{
    width: 82%;
  }
  .front{background: url("../img/logo.png") no-repeat center center !important;}
}



/* --------------------------------------------------------------------  
    ANIMATION
    -----------------------------------------------------------------------  */

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

/* --------------------- */
.hover-txt-top{
  position: absolute;
  top: 15%;
  opacity: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 100;
  width: 95%;
}
a:hover .hover-txt-top {
  opacity: 1;
  visibility: visible;
  transform: translateY(20%);
}

/* -------- */
.animate-from-top{
  -webkit-transform:translateY(-270px);
  -ms-transform:translateY(-270px);
  transform:translateY(-270px);
  -webkit-transition:-webkit-transform .379s ease-in-out 0s;
  transition:transform .379s ease-in-out 0s;
}
.animate-from-top.animate ul li{
  -webkit-transform:translateY(0px);
  -ms-transform:translateY(0px);
  transform:translateY(0px);
}

/* ICON scroll - ANIMATION -------------- */
@-webkit-keyframes scrollanimation {
  0% {
    height: 20px;
  }
  50% {
    height: 36px;
  }
  100% {
    height: 20px;
  }
}
@-moz-keyframes scrollanimation {
  0% {
    height: 20px;
  }
  50% {
    height: 36px;
  }
  100% {
    height: 20px;
  }
}
@-ms-keyframes scrollanimation {
  0% {
    height: 20px;
  }
  50% {
    height: 36px;
  }
  100% {
    height: 20px;
  }
}

@keyframes scrollanimation {
  0% {
    height: 20px;
  }
  50% {
    height: 36px;
  }
  100% {
    height: 20px;
  }
}


/* BG _ ANIMATION ------------------------------------------ */

@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}
@-moz-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}
@-ms-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}
@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}



/* OVERCLASS --------------------- */
.home .innerContent{
  background-color: transparent !important;
}
.home #services .innerContent{
  background-color: #141414 !important;
}
.innerContent{
  overflow: hidden;
}
.work-details .innerContent{
  overflow: visible;
}


#ascrail2000-hr{
  display: none !important;
}


/* CIRCLE HOME-ITEM --------------------- */

.ch-grid {
  margin: 20px 0 0 -50px;
  padding: 0;
  list-style: none;
  display: block;
  text-align: center;
  width: 100%;
}
.ch-grid:after,
.ch-item:before {
  content: '';
  display: table;
}
.ch-grid:after {
  clear: both;
}
.ch-grid li {
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 20px -80px 0 20px;
}
.ch-grid li.special{
  width: 220px;
  height: 220px;
  position: relative;
  top: 15px;
  z-index: 4;
}
.ch-item {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  cursor: default;
  opacity: 1;filter: alpha(opacity=100);

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.ch-1 {
  background: #b20038;
  z-index: 1;
}
.ch-2 {
  background: rgba(250,250,250, 0.7);
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 2;
  /*border: 8px solid #b20038;*/
  border: 6px solid rgba(153,126,82, 0.5);

}
.ch-3 {
  background: #222222;
  z-index: 1;
}

.ch-info {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  -webkit-backface-visibility: hidden;

}
.ch-info h3 {
  color: #FFFFFF;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;
  font-size: 22px;
  margin: 10px;
  padding: 25px 0;
  height: 110px;
  font-family: "Lato", Arial, sans-serif;
}
.ch-item:hover {
  z-index: 99 !important;
  opacity: 1;filter: alpha(opacity=100);
}
.ch-item:hover .ch-info {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background:white;
  border: 8px solid #b20038;
  z-index: 6 !important;
}

.ch-item:hover .ch-info h3{
  color: #222222 ;
}

.ch-item:hover .ch-info.special {
  background:#b20038;
}
.ch-item:hover .ch-info.special h3{
  color: #FFFFFF !important ;
  padding: 25px 0;
}
.ch-grid li:hover{
  z-index: 9;
}

.no-cssanimations .support-note span.no-cssanimations,
.no-csstransforms .support-note span.no-csstransforms,
.no-csstransforms3d .support-note span.no-csstransforms3d,
.no-csstransitions .support-note span.no-csstransitions{
  display: block;
}

.services .ch-item .fa{
  top: 36%;
  font-size: 2.5em;
}
.services .ch-item.ch-3 .fa{
  color: white !important;
}
.ch-item.ch-2:hover{
  border: 8px solid #FFFFFF;
}


/* ===================================================================================
   ===================================================================================  
    3LP - overwrite
======================================================================================
======================================================================================  */


/* --------------------------------------------------------------------  
   3LP COLORS 
   -----------------------------------------------------------------------  */
.black-bg{
  background-color:black;
}
/* Reokace wuth .3lp if replace in html */
body.3lp, .3lp .row-container, body.3lp .row-description{
                                       background-color:#FFFFFF;
                                     }
.3lp h1, .3lp h2, .3lp h3, .3lp h4, .3lp h5, .3lp h6,
.3lp [class^="b-"], .3lp .navBtn {
                        color:#FFFFFF;
                      }
.3lp ul, .3lp li, .3lp a,
.3lp #menu li a, .3lp a:hover, .3lp a:active, .3lp a:focus{
                                                  color: #b20038;
                                                  /*line-height: 0.65em;*/
                                                  line-height: 1.2em;
                                                }
.3lp .innerContent, .3lp .nav-slide div {
                        background-color:#FFFFFF;
                      }
.3lp .srv-item a, .3lp .srv-item h3,
.3lp .works-item a, .3lp .works-item h3{
                        color: white;
                      }
.3lp .white-bg, .3lp .highlight .innerContent {
                    background-color:#FFFFFF !important;
                    color: #141414;
                  }
body.3lp .row-description h2, body.3lp .row-description p,
                                    .share li a i.fa{
                                      color: #141414;
                                    }
.3lp .row-description .txt-item-inner{
    background-color: #FFFFFF;
    color: #FFFFFF;
  }
.3lp .row-description .txt-item-inner .info-list li{
    color: #AAAAAA;
  }
.3lp .l-line{
    /*display: none;*/
    background: #b20038;
  }
.3lp #sidebar, .3lp #sidebar .logo{
                   background: #FFFFFF;
                   color: #FFFFFF;
                 }
.3lp #sidebar .fa{
    color: #141414;
  }

.timeline .timeline-heading h4{
  color: rgba(250, 250, 250, 0.75);
}

/*.3lp .srv-item a:hover{background-color: #141414;}*/

.3lp #sidebar .menu li a, #sidebar .menu li a span{
    background: transparent;
    color: #141414;
  }
.3lp #sidebar .menu li a:hover,.3lp #sidebar .menu li a:hover span,
                                 .adress .inner a:hover, .adress a:hover, .works-item p span{
                                   background: transparent;
                                   color: #b20038;
                                 }
.banner header h1 span, .parallax header h1 span, .banner.white-bg h1 span{
  color: #b20038;
  text-transform: uppercase;
}
.banner header h1 span, .parallax header h1 span{
  color:rgba(250, 250, 250, 0.6) !important;
}
.txt-item-inner h3, .banner header h1, .parallax header h1,
.slides h1, .slides h1 span, .slides h2, #gmap-popup h3,
.timeline .timeline-image h4, .info article h2,
.3lp .work-details .info h2,
  .adress .txt-item-inner p, .adress p a,
  .banner header h1 span, .parallax header h1 span,
  .timeline .timeline-heading h4, .banner.white-bg h1 span{
    font-family: "Lato", Arial, sans-serif !important;
  }

.banner-bg{
  height: 440px;
  width: 100%;
  position: relative;
}
.3lp .highlight .txt-item-inner h3{
    color: #141414;
  }
article p, .services .txt-item-inner p, .services .txt-item-inner p > span,
.txt-item-inner p, .txt-item-inner p > span{
  font-family: "Lato", Arial, sans-serif !important;
}
.txt-item-inner p, .txt-item-inner p > span{
  font-style: italic;
  font-weight: 300;
  line-height: 25px;
}
.services .txt-item-inner p, .services .txt-item-inner p > span{
  padding: 1% 2%;
}
.adress .txt-item-inner p, .adress p a{
  font-style: normal;
  text-transform: uppercase !important;
  text-decoration: none !important;
}
.adress .txt-item-inner p > span{
  color: #FFFFFF !important;
}
article p{
  color: #141414;
  opacity: 0.85;
  filter: alpha(opacity=85);
}
.3lp .info h2, .info h2, .3lp .vertical-wrap .info h2{
                             font-size: 2.4em !important;
                             color: #b20038 !important;
                             text-transform: uppercase !important;
                           }
body.3lp .info article p{
        font-size: 0.65em !important;
      }
body.3lp .info article h2{
        display: none;
      }
.info .white-bg .share-toggle.open-share{
  background: transparent !important;
}
.info article .inner p, .info-content-title{
  text-transform: uppercase;
  font-weight: 400;
}
@media screen and (min-width: 1220px) and (max-width: 1330px){
  .info article .inner p, .info-content-title{
    font-size: 0.7em;
  }
}

.info article p{
  font-size: 0.8em;
}
body.pc p, body.pc .highlight p span{
  font-size: 0.95em ;
  font-weight: 400 ;
}
.home .vb-2 .flex-direction-nav, .home .vb-2 .flex-control-nav, .home .vb-2 .flex-control-paging{
  display: none !important;
}
.share iframe {
  max-width: none;
}
.3lp .white-bg p, .3lp .white-bg h2{
                      color: #121212 !important;
                    }


@media only screen and (min-device-width: 481px) and (max-device-width : 1023px) and (orientation : landscape) {

  #sidebar .menu{
    top: 34%;
  }
  .logo a img{
    padding: 0 70px;
  }
  #sidebar .menu li a{
    padding: 5px 8px 5px 16px;
  }
  #sidebar .menu li a span{
    font-size: 17px;
  }
  #section0 .innerContent.video{
    position: relative;
    top: -34px
  }

}


/* ========================================================================
    JOBS 
    ========================================================================  */

@media screen and (min-width: 1600px) {
  .jobs .b2, .jobs .b3{
    min-height: 580px;
  }
}
@media screen and (min-width: 1800px) {
  .jobs .b2, .jobs .b3{
    min-height: 440px;
  }
}

.jobs .b2 .innerContent, .jobs .b3 .innerContent{
  position: relative;
}
.jobs .txt-item-inner h3{
  line-height: 1.3em;
}

.bbw .parallax h2 {
  text-align: center;
}
.bbw .jobs [class^="b-"], .bbw .jobs .highlight .innerContent,
.bbw .jobs .b2 .innerContent, .bbw .jobs .b2 .innerContent article,
.bbw .jobs .b3 .innerContent, .bbw .jobs .b3 .innerContent article {
  background :#141414 !important;
  color: #e8e8e8;
}
.bbw .jobs .highlight .txt-item-inner h3, .bbw .jobs article p, .bbw .jobs article p span{
  color: #e8e8e8 !important;
}
/* ------------- end Jobs txt Colors */

.bbw .jobs article p, .bbw .jobs article p span{
  font-style: normal;
}
.jobs .b2 article, .jobs .b2 .innerContent article.half-h,
.jobs .b3 article, .jobs .b3 .innerContent article.half-h{
  width: 100%;
  height: auto;
}
.jobs .banner header h1{
  font-size: 2.5em;
  color: #997e52;
}
.jobs .b2 article p, .jobs .b2 article p span,
.jobs .b3 article p, .jobs .b3 article p span{
  text-align: left !important;
}
.jobs .b2 article p span, .jobs .b3 article p span{
  padding: 0;
  font-size: 0.9em;
}
.jobs .links li:first-child{
  margin-left: 0;
  margin-top: 25px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.jobs .links li:first-child:hover{
  -webkit-filter: none;
  filter: none;
}
.jobs .links li{
  float: left;
  margin-left: 14px;
  display: inline-block;
}
.jobs article ul{
  display: block;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}
.jobs article ul li{
  display: block;
  font-family: "Lato",sans-serif;
  font-size: 0.85em;
  line-height: 1.2em;
  margin-top: 5px;
  padding:0;
  text-align: left;
  position: relative;
}
.jobs .job article ul li{
  padding: 4px 4px 4px 20px;
}

.jobs .job article ul li:before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 8px;
  height: 8px;
  background: #333;
  z-index: 2;
}
.jobs .b2 article p a, .jobs .b3 article p a, .jobs article ul li a{
  color: #888888 ;
}
.jobs .b2 article p a:hover, .jobs .b3 article p a:hover, .jobs article ul li a:hover{
  color: #b20038 ;
}

.jobs .b3:first-child article{
  float: right;
  width: 99%;
}
body .jobs .b3:first-child article, body .jobs .b3:first-child .innerContent article{
  background: #E8E8E8 !important;
}
.jobs .b3:first-child .txt-item-inner h3, .jobs .txt-item-inner h3{
  color: #b20038 !important;
}


.jobs .btn{
  border: 2px solid #111111 !important;
  background: #FFF;
  color: #000;
  padding: 1em;
  float: none;
  font-size: 0.9em;
  font-family: "Lato", sans-serif;
}
.jobs .btn:hover{
  background: #FFF;
  color: #000;
  border: 2px solid #111111;
}

/* JOB DETAILS -------------------------------- */

.job .b10{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.job .b10 .innerContent{
  position: relative;
}

@media screen and (min-width: 1024px){
  .job{
    width: 90%;margin: 0 auto;
  }
}

@media screen and (min-width: 1440px){
  .job{
    width: 70%;margin: 0 auto;
  }
}
.job {
  max-width:1200px;
  margin: 30px auto;
  padding: 30px;
}
.job.b-bot{
  border-bottom: 1px solid rgba(132,116,90, .4);
}
.job .b10 article, .job .b10 .innerContent article.half-h{
  width: 100%;
  height: auto;
  text-align: left;
}
.jobs .b10 article span{
  padding: 0;
}
.job .txt-item-inner h3{
  text-align: left;
}
.job .btn{
  float: none;
  margin: 10px auto;
  width: 40%;
  display: block;
  border: 2px solid white;
}

/* MEDIAQUERIES -------------- */
@media screen and (max-width: 1024px) {
  .jobs .b2{
    float: none;
    margin: 0 auto;
    width: 99%;
  }
  .jobs .b3:first-child article{
    float: none;
    margin: 0 auto;
    width: 99%;
  }
}

@media screen and (min-width: 990px) and (max-width: 1440px){

  .jobs .links li{
    display: block;
    float: none !important;
    margin-left: 0 !important;
    text-align: left;
  }
  .jobs .links li:first-child{
    padding-left: 18px;
  }
}

@media screen and (max-width: 480px) {
  .jobs .banner header h1{
    font-size: 2em;
    margin-top: 40px;
  }
  .jobs .links li{
    display: block;
    float: none !important;
    margin-left: 0 !important;
    text-align: left;
  }
  .jobs .links li:first-child{
    padding-left: 18px;
  }
  .bbw .jobs .highlight .txt-item-inner h3{
    text-align: left;
    line-height: 1.5em;
  }
  .job .btn{
    width: 60%;
  }
}


/* JOBS > APPLY - FORM ======================================================================== */

.jobs #form-container{
  margin-top: 25px;
}
.b2.form_wrapper{
  margin: 0 auto;
  float: none;
}
@media screen and (max-width: 1440px) {
  .b2.form_wrapper{
    width:70% !important;
  }
}
@media screen and (max-width: 1024px) {
  .b2.form_wrapper{
    width:85% !important;
  }
}
@media screen and (max-width: 800px) {
  .b2.form_wrapper{
    width: 100% !important;
  }
}
.jobs legend{
  text-transform: uppercase;
  border-bottom: 1px solid #E8E8E8;
  color: #222222;
  display: block;
  font-weight: normal;
  margin: 20px 0;
  padding: 6px;
  text-align: center;
  width: 100%;
}
.jobs legend.sub{
  border-bottom: none;
  border-top:none;
  color: #777777;
  display: block;
  margin:5px 0;
  padding: 6px 6px 10px 24px;
  text-align: left;
  font-weight: lighter;
}

.jobs input, select, textarea{
  text-indent: 2px;
}

.fbox {
  padding: 4px 0;
  margin: 8px 0;
}
textarea:focus{
  min-height:160px;
  max-height:220px;
}

/* SELECT STYLED CUSTOM ========================================== */
.jobs .select-style {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 99;
  background: url("../img/select-arrow.png") no-repeat scroll right center;
  text-align: left;
  height: 32px;
  border-bottom: 1px solid white;
}
.jobs .select-style select {
  width: 102%;
  background-color: transparent !important;
  padding: 0;
  text-transform: lowercase;
  font-size: 0.9em;
  text-align: left;
  z-index: 2;
}
.jobs select:checked, .jobs select:active, .jobs .select-style option {
  border: none !important;
}
.jobs .select-style option{
  padding: 5px 2px;
  text-transform: lowercase;
  box-shadow: 0;
  border: none !important;
}

/* UPLAD INPUT ---------------------- */
.upld-input-group {
  border-collapse: separate;
  display: table;
  margin-top: 8px;
  width: 100%;
}
.input-group-btn {
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  width: 1%;
}
.input-group-btn {
  display: table-cell;
}
.input-group-btn > .btn {
  position: relative;
}
.btn-file {
  overflow: hidden;
  position: relative;
}
.upld-input-group .btn {
  -moz-user-select: none;
  cursor: pointer;
  /*display: inline-block;*/
  /* for the float right-left position - and take off the upload-btn div */
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 0;
  padding: 8px;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-file input[type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.jobs .select-style i{
  position: absolute;
  top: 50%;
  right: 5px;
  width: 16px;
  height: 12px;
}
.jobs .select-style i.ico-select{
  background: url("../img/select-arrow.png") no-repeat left center;
  display: block;
  height: 24px;
  width: 28px;
  z-index: 0;
}


/* CHECKBOX & RADIO CUSTOM ========================================== */


input[type=radio],
input[type=checkbox] {
  visibility: hidden;
  text-indent: 0;
  width: 4px;
}
.radio label, .checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  margin-right: 12px;
  font-size: 14px;
  color: white;
  font-family: "Lato";
  font-weight: lighter;
}
.radio label{
  margin-right: 4px;
}
.radio label:before, .checkbox label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  bottom: 1px;
  border: 1px solid #111111;
  background-color: #E8E8E8;
}
.radio label:before {
  border-radius: 8px;
}
.checkbox-wrap{
  clear: both;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}
.checkbox label {
  margin-bottom: 10px;
  color: #777777;
}

input[type=radio]:checked + label:before {
  content: "\2022";
  color: #FFFFFF;
  font-size: 25px;
  text-align: center;
  line-height: 11px;
  background-color: #111111;
}
input[type=checkbox]:checked + label:before {
  content: "\2713";
  font-size: 15px;
  color: #FFFFFF;
  text-align: center;
  line-height: 14px;
  background-color: #111111;
}
.check-col{
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
  width: 49.999%;
}
@media screen and (max-width: 480px) {
  .check-col{
    width: 100%;
  }
}
.checkwrap{
  display: block;
  position: relative;
  padding: 4px 0;
}

/* HIDDEN RADIO BTN  ------------------ */
.checkwrap .level{
  margin-bottom: 10px;
  padding: 10px 16px;
  overflow: hidden;
}
.checkwrap .level .radio label{
  display: inline-block;
  float: left;
  margin-bottom: 5px !important;
}

@media screen and (max-width: 600px) {
  .radio label{
    width: 6%;
    color: #fff !important;
  }
}

p.notes{
  color: #111111;
  font-family: "Lato",Arial,Helvetica,sans-serif;
  font-weight: lighter;
  opacity: 1;
}



/* Cookie bar  ------------------ */

#cookie-bar{
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 25px;
  text-align: center;
  font-weight: 300;
  display: flex;
}
#cookie-bar a{
  color: #b20038;
  font-weight: 400;
}
#cookie-bar p{
  margin: auto 0 auto 100px;
  width: 70%;
  text-align: left;
}
#cookie-bar .close{
  display: block;
  position: relative;
  z-index: 9999;
  width: 30px;
  height: 30px;
  right: 0;
  top: 35%;
  font-size: 26px;
  font-weight: 300 !important;
  color: #b20038;
  margin: auto 0;
}
#cookie-bar .close i, #cookie-bar .close .fa-close{
  color: #b20038;
}
#cookie-bar .btn{
  border: 3px solid #b20038;
  white-space: nowrap;
  margin: auto;
}
#cookie-bar .btn:hover {
  color: white;
}

@media screen and (max-width: 1300px){
  #cookie-bar p {
    margin: auto 20px auto 0;
  }
  #cookie-bar .close{
    right: 0;
  }
}
@media screen and (max-width: 800px) {
  #cookie-bar {
    display: block;
    padding: 25px 25px 15px;
  }
  #cookie-bar p {
    width: 100%;
  }
  #cookie-bar .btn {
    font-size: 14px;
    margin-top: 10px;
  }
  #cookie-bar .close{
    margin-top: 15px;
  }
}

.form_wrapper form.classic .col{
    display: flex;
}
.form_wrapper form.classic .col-4{
    width: 30%;
}
.form_wrapper form.classic .col-8{
    width: 63%;
}

.basic-page .txt-item-inner{
  text-align: left;
}
.basic-page .txt-item-inner p, .basic-page .txt-item-inner ul{
  padding: 10px 2%;
}
.basic-page .txt-item-inner li{
  padding: 0 0 5px 30px;
  position: relative;
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1em;
  font-style: italic;
  font-weight: 400;
  opacity: 0.85;
  line-height: 1em;
}
.basic-page .txt-item-inner li:before{
  background-color: #b20038;
  height: 5px;
  width: 5px;
  content: '';
  border-radius: 50%;
  left: 10px;
  position: absolute;
  top: calc(0.5em - (5px / 2));
}
.basic-page .txt-item-inner a{
  text-decoration: underline;
}

#thumbnails_container ul{
  margin-top: 15px;
}
#thumbnails_container ul li, #thumbnails_container span{
  padding: 15px;
  background: #e9e9e9;
  font-size: 18px;
  border-left: 2px solid #b20038;
  margin-top: 10px;
  display: block;
}
#thumbnails_container span.error i{
  color: #b20014;
  margin-right: 5px;
}
#thumbnails_container .not_supported_remove_files{
  text-align: center;
  padding: 5px;
  display: block;
  border: 1px solid #111;
  margin: 10px auto;
  width: 100px;
}